import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { contentArea } from '../constants';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div css={contentArea}>
      <h1>GREAT SCOTT!!!</h1>
      <p>It seems that the creation of that page hasn&#39;t happened yet...</p>
    </div>
  </Layout>
);

export default NotFoundPage;
